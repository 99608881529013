import React from "react";

import Page from "../../components/Page";
import BannerParallax from "../../components/BannerParallax";

import imgBackground from "../../images/banner/banner-6-contact-us.jpg";
import ContactUs from "./ContactUs";

export default () => {
  const pageTitle = "Contact Us";
  return (
    <Page title={pageTitle}>
      <BannerParallax title={pageTitle} background={imgBackground} />
      <ContactUs />
    </Page>
  );
};
