import React from "react";

export default () => {
  return (
    <section className="contact-section section_padding">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12">
            <div className="section_title text-center">
              <h2>Contact Us</h2>
            </div>
          </div>
        </div>
        <div className="row mb-md-4 mb-2">
          <div className="col-md-8">
            <div className="mb-5">
              <iframe
                title="musir republicain location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.6912678390145!2d106.79202231466502!3d-6.30423366345205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ee3d695dd331%3A0xf09dd01b178ef06f!2sMusic%20Republicain!5e0!3m2!1sen!2sus!4v1572776575706!5m2!1sen!2sus"
                width="100%"
                height="600"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="media contact-info">
              <div className="media-body">
                <h3>Jl. RS. Fatmawati no 8, Pondok Labu</h3>
                <p>Jakarta Selatan 12450</p>
              </div>
            </div>
            <div className="media contact-info">
              <div className="media-body">
                <h3>(021) 751-1374</h3>
                <p>Telp &amp; Fax</p>
              </div>
            </div>
            <div className="media contact-info">
              <div className="media-body">
                <h3>
                  <a href="https://wa.me/6281389113119">081-389-113-119</a>
                </h3>
                <p>WhatsApp</p>
              </div>
            </div>
            <div className="media contact-info">
              <div className="media-body">
                <div className="d-flex">
                  <h3 className="mr-2">
                    Monday - Friday <br />
                    Saturday
                  </h3>
                  <h3 className="text-right">
                    10:30am - 7:30pm
                    <br />
                    8:30am - 5:00pm
                  </h3>
                </div>
                <p>Opening Hours</p>
              </div>
            </div>
            <div className="media contact-info">
              <div className="media-body">
                <h3>info@music-republic.com</h3>
                <p>General inquiry</p>
                <h3>ivonne@music-republic.com</h3>
                <p>Ivonne Atmojo</p>
                <h3>ivana@music-republic.com</h3>
                <p>Ivana Atmojo</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
